<template>
  <v-container>
    <ValidationObserver ref="objects" v-slot="{ invalid }">
      <v-form>
        <v-row>
          <v-col cols="12">
            <ValidationProvider name="Group" rules="required">
              <v-autocomplete
                v-model="group"
                :loading="loading"
                :items="groups"
                :search-input.sync="search"
                no-data-text="Group not found"
                item-text="title"
                item-value="id"
                auto-select-first
                clearable
                cache-items
                flat
                hide-no-data
                hide-details
                label="Group"
                return-object
              ></v-autocomplete>
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider name="Subgroup" rules="required">
              <v-select
                v-model="duplicate_subgroup_id"
                :disabled="loading"
                :items="listSubGroups"
                :menu-props="{ maxHeight: '400' }"
                item-text="title"
                item-value="id"
                label="Subgroup"
                auto-select-first
                clearable
              ></v-select>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-btn @click="okAction" :disabled="loading || invalid" class="primary">Ok</v-btn>
      </v-form>
    </ValidationObserver>
  </v-container>
</template>

<script>
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { mapGetters } from 'vuex';
import store from '@/store/index';

extend('required', required);

export default {
  name: 'SubgroupNameListGroupSelectForm',
  components: {
    ValidationProvider,
    ValidationObserver
  },
  props: ['group_id'],
  computed: {
    /**
     * The mapGetters helper simply maps store getters to local computed properties:
     */
    ...mapGetters('group', ['loading', 'groups', 'error']),
    /**
     * Get the subgroups from the group
     *
     * @returns array
     */
    listSubGroups() {
      if (this.group) {
        return this.group.subgroups;
      }
      return [];
    }
  },
  watch: {
    search: {
      handler(value) {
        if (!this.enableSearch) return false;
        if (this.loading) return false;
        if (value == null || value == '') return false;
        if (value && value.indexOf(',') > 0) return false;
        this.searchTerm = value;
        this.fetchEntriesDebounced();
      }
    },
    group_id: {
      immediate: true,
      handler(value) {
        store.dispatch('group/search', {
          group_id: value
        });
      }
    },
    groups: function (newValue, oldValue) {
      if (newValue !== oldValue && newValue.length && !this.enableSearch) {
        this.group = newValue[0];
        setTimeout(() => {
          this.enableSearch = true;
        }, 1000);
      }
    }
  },
  methods: {
    /**
     * Fetch the groups with a debounced event to reduce calls to server
     */
    fetchEntriesDebounced() {
      clearTimeout(this.timerID);
      this.timerID = setTimeout(() => {
        this.searchGroups();
      }, 1000);
    },
    /**
     * Search for the groups
     */
    searchGroups() {
      store.dispatch('group/search', {
        keyword: this.searchTerm
      });
    },
    /**
     * Ok method for the form
     */
    async okAction() {
      this.$emit('closed', this.duplicate_subgroup_id);
    }
  },
  data: () => ({
    enableSearch: false,
    group: null,
    search: null,
    timerID: null,
    searchTerm: null,
    duplicate_subgroup_id: null
  })
};
</script>

import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c('ValidationObserver',{ref:"objects",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c(VForm,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Person","rules":"required"}},[_c(VAutocomplete,{attrs:{"loading":_vm.loading,"items":_vm.people,"search-input":_vm.search,"no-data-text":"Person not found","item-text":"title","item-value":"id","auto-select-first":"","clearable":"","cache-items":"","flat":"","hide-no-data":"","hide-details":"","label":"Person"},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},model:{value:(_vm.person_id),callback:function ($$v) {_vm.person_id=$$v},expression:"person_id"}})],1)],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VBtn,{staticClass:"primary",attrs:{"disabled":_vm.loading || invalid},on:{"click":_vm.okAction}},[_vm._v("Ok")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
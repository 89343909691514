import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c('ValidationObserver',{ref:"objects",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c(VForm,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Group","rules":"required"}},[_c(VAutocomplete,{attrs:{"loading":_vm.loading,"items":_vm.groups,"search-input":_vm.search,"no-data-text":"Group not found","item-text":"title","item-value":"id","auto-select-first":"","clearable":"","cache-items":"","flat":"","hide-no-data":"","hide-details":"","label":"Group","return-object":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},model:{value:(_vm.group),callback:function ($$v) {_vm.group=$$v},expression:"group"}})],1)],1),_c(VCol,{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Subgroup","rules":"required"}},[_c(VSelect,{attrs:{"disabled":_vm.loading,"items":_vm.listSubGroups,"menu-props":{ maxHeight: '400' },"item-text":"title","item-value":"id","label":"Subgroup","auto-select-first":"","clearable":""},model:{value:(_vm.duplicate_subgroup_id),callback:function ($$v) {_vm.duplicate_subgroup_id=$$v},expression:"duplicate_subgroup_id"}})],1)],1)],1),_c(VBtn,{staticClass:"primary",attrs:{"disabled":_vm.loading || invalid},on:{"click":_vm.okAction}},[_vm._v("Ok")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-container>
    <ValidationObserver ref="objects" v-slot="{ invalid }">
      <v-form>
        <v-row>
          <v-col cols="12">
            <ValidationProvider name="Person" rules="required">
              <v-autocomplete
                v-model="person_id"
                :loading="loading"
                :items="people"
                :search-input.sync="search"
                no-data-text="Person not found"
                item-text="title"
                item-value="id"
                auto-select-first
                clearable
                cache-items
                flat
                hide-no-data
                hide-details
                label="Person"
              ></v-autocomplete>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn @click="okAction" :disabled="loading || invalid" class="primary">Ok</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </ValidationObserver>
  </v-container>
</template>

<script>
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import PersonService from '@/services/PersonService';

extend('required', required);

export default {
  name: 'SubgroupAddPersonForm',
  components: {
    ValidationProvider,
    ValidationObserver
  },
  watch: {
    search(value) {
      if (this.loading) return false;
      if (value == null || value == '') return false;
      if (value && value.indexOf(',') > 0) return false;
      this.searchTerm = value;
      this.fetchEntriesDebounced();
    }
  },
  methods: {
    /**
     * Fetch the data with a debounced event to reduce calls to server
     */
    fetchEntriesDebounced() {
      clearTimeout(this.timerID);
      this.timerID = setTimeout(() => {
        this.searchAction();
      }, 1000);
    },
    /**
     * Search for the people
     */
    searchAction() {
      PersonService.search({
        keyword: this.searchTerm
      }).then(response => {
        this.people = response.data.data;
      });
    },
    /**
     * Save method for the form
     */
    async okAction() {
      this.$emit('closed', {
        person_id: this.person_id
      });
      this.person_id = null;
    }
  },
  data: () => ({
    people: [],
    person_id: null,
    search: null,
    timerID: null,
    searchTerm: null,
    loading: false
  })
};
</script>

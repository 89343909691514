<template>
  <v-container>
    <v-alert v-if="hasDuplicates" type="warning">There is a duplicate entries in the name list</v-alert>
    <v-form>
      <v-row>
        <v-col cols="6" md="2">
          <v-text-field
            v-model="subgroupLocal.namelist_version"
            :disabled="loading"
            label="Namelist Version"
            @change="updateNamelist"
          ></v-text-field>
        </v-col>
        <v-col cols="6" md="2">
          <v-switch v-model="subgroupLocal.namelist_full" label="Namelist full" @change="updateNamelist"></v-switch>
        </v-col>
        <v-col cols="6" md="2">
          <v-switch v-model="subgroupLocal.namelist_sent" label="Sent to client" @change="updateNamelist"></v-switch>
        </v-col>
        <v-col cols="12" md="6" class="d-flex justify-end">
          <v-btn color="primary" dark class="ma-2" @click="addPerson()">Add Person</v-btn>
          <v-btn color="primary" dark class="ma-2" @click="newPerson()">New Person</v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-data-table
      :headers="headers"
      :items="people"
      :options.sync="options"
      @click:row="editItem"
      :loading="loading"
      loading-text="Loading... Please wait"
      :items-per-page="items_per_page"
      v-model="selectedRows"
      hide-default-footer
      show-select
    >
      <template v-slot:item.dob="{ item }">
        {{ formatDate(item.dob) }}
      </template>
      <template v-slot:item.full_stay="{ item }">
        {{ item.full_stay ? 'Yes' : 'No' }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-4" @click.stop="editDetails(item)"> mdi-pencil </v-icon>
        <v-icon small @click.stop="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
    <v-btn color="primary mt-5" v-if="selectedRows.length" dark @click="moveSelected()">Move</v-btn>
    <Paginate :meta="meta" v-on:paginate="paginateAction" />

    <v-dialog width="480" :fullscreen="$vuetify.breakpoint.xsOnly" v-model="showAddPersonDialog">
      <template>
        <v-card>
          <v-toolbar color="primary" dark>
            <v-toolbar-title>Add Person</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="showAddPersonDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <SubgroupAddPersonForm :isvisible="showAddPersonDialog" @closed="onSubgroupAddPersonFormClosed" />
        </v-card>
      </template>
    </v-dialog>

    <ConfirmDialog
      title="Confirm delete"
      description="Are you sure you want to delete this item"
      okayLabel="Yes"
      cancelLabel="No"
      :show="showDeleteConfirmDialog"
      @confirm-dialog-clicked="confirmDelete"
    />

    <v-dialog max-width="600" width="auto" :fullscreen="$vuetify.breakpoint.xsOnly" v-model="showMoveConfirmDialog">
      <template>
        <v-card>
          <v-toolbar color="primary" dark>
            <v-toolbar-title>Move Students</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="showMoveConfirmDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <SubgroupNameListGroupSelectForm
            :group_id="subgroup.group_id"
            :isvisible="showMoveConfirmDialog"
            @closed="onMoveConfigDialogClosed"
          />
        </v-card>
      </template>
    </v-dialog>
  </v-container>
</template>
<script>
import { dateMixin } from '@/mixins/dateMixin';
import { mapGetters } from 'vuex';
import store from '@/store/index';
import ConfirmDialog from '@/views/dialogs/ConfirmDialog';
import SubgroupNameListGroupSelectForm from '@/views/SubgroupNameListGroupSelectForm';
import SubgroupAddPersonForm from '@/views/SubgroupAddPersonForm';
import Paginate from '@/views/Paginate';

export default {
  name: 'SubroupNameList',
  beforeRouteEnter(to, from, next) {
    const currentPage = parseInt(to.query.page) || 1;
    store
      .dispatch('person/list', {
        subgroup_id: to.params.id,
        page: currentPage,
        per_page: 100,
        sort_by: 'surname',
        sort_desc: true,
        full_stay: true
      })
      .then(() => {
        to.params.page = currentPage;
        next();
      });
  },
  components: {
    ConfirmDialog,
    SubgroupNameListGroupSelectForm,
    SubgroupAddPersonForm,
    Paginate
  },
  mixins: [dateMixin],
  computed: {
    ...mapGetters('person', ['loading', 'error', 'people', 'meta', 'links']),
    ...mapGetters('subgroup', ['subgroup']),
    subgroupLocal: function () {
      const subgroup = {
        id: this.subgroup.id,
        namelist_full: this.subgroup.namelist_full,
        namelist_sent: this.subgroup.namelist_sent,
        namelist_version: this.subgroup.namelist_version
      };
      return subgroup;
    }
  },
  methods: {
    addPerson: function () {
      this.showAddPersonDialog = true;
    },
    newPerson: function () {
      this.$router.push({
        path: `/people/new?subgroup_id=${this.subgroup.id}&people_types_id=1`
      });
    },
    updateNamelist() {
      store.dispatch('subgroup/save', {
        id: this.subgroupLocal.id,
        subgroup: this.subgroupLocal
      });
    },
    onSubgroupAddPersonFormClosed(props) {
      this.showAddPersonDialog = false;
      store.dispatch('subgroup/peopleAttach', {
        subgroup_id: this.subgroupLocal.id,
        person_id: props.person_id
      });
      this.load();
    },
    editItem(item) {
      this.$router.push({
        path: `/namelists/?subgroup_id=${this.subgroupLocal.id}&people_id=${item.id}`
      });
    },
    editDetails(item) {
      this.$router.push({
        path: `/people/${item.id}?subgroup_id=${this.subgroup.id}&people_types_id=1`
      });
    },
    deleteItem(item) {
      this.person_id = item.id;
      this.showDeleteConfirmDialog = true;
    },
    moveSelected() {
      this.itemsToMove.splice(0, this.itemsToMove.length);
      this.itemsToMove = this.selectedRows.map(a => a.id);
      this.showMoveConfirmDialog = true;
    },
    onMoveConfigDialogClosed: function (subgroup_id) {
      this.showMoveConfirmDialog = false;
      if (subgroup_id > 0) {
        this.processMove(subgroup_id);
      } else {
        alert('none selected');
      }
    },
    async processMove(subgroup_id) {
      for (const person_id of this.itemsToMove) {
        await store.dispatch('subgroup/peopleDetach', {
          subgroup_id: this.subgroupLocal.id,
          person_id: person_id
        });
        await store.dispatch('subgroup/peopleAttach', {
          subgroup_id: subgroup_id,
          person_id: person_id
        });

        this.selectedRows = this.selectedRows.filter(x => {
          return x.id != person_id;
        });
      }
      this.load();
    },
    confirmDelete: function (deleteItem) {
      this.showDeleteConfirmDialog = false;
      if (deleteItem) {
        this.processDelete();
      }
    },
    async processDelete() {
      await store.dispatch('subgroup/peopleDetach', {
        subgroup_id: this.subgroupLocal.id,
        person_id: this.person_id
      });
      this.load();
    },

    paginateAction(item) {
      if (item == 'next') {
        store.dispatch('person/paginate', this.links.next);
      } else if (item == 'previous') {
        store.dispatch('person/paginate', this.links.prev);
      }
    },
    load() {
      if (this.subgroup.id) {
        store.dispatch('person/list', {
          subgroup_id: this.subgroup.id,
          page: this.options.page,
          per_page: this.items_per_page,
          sort_by: this.options.sortBy[0] ?? 'surname',
          sort_desc: this.options.sortDesc[0] ?? false
        });
      }
    }
  },
  watch: {
    options: {
      handler() {
        if (this.options.init) {
          this.load();
        } else {
          this.options.init = true;
        }
      },
      deep: true
    },
    people: {
      handler(newValues) {
        let seen = new Set();
        const hasDuplicates = newValues.some(function (currentObject) {
          return (
            seen.size === seen.add(currentObject.first_name.toLowerCase() + currentObject.surname.toLowerCase()).size
          );
        });
        this.hasDuplicates = hasDuplicates;
      },
      deep: true,
      immediate: true
    }
  },
  data: () => ({
    showDeleteConfirmDialog: false,
    showMoveConfirmDialog: false,
    showAddPersonDialog: false,
    hasDuplicates: false,
    options: {},
    items_per_page: 100,
    selectedRows: [],
    itemsToMove: [],
    itemsToMoveSubgroup: 0,
    headers: [
      { text: 'First Name', value: 'first_name' },
      { text: 'Surname', value: 'surname' },
      { text: 'Type', value: 'type' },
      { text: 'Gender', value: 'gender' },
      { text: 'Status', value: 'status' },
      { text: 'DOB', value: 'dob' },
      { text: 'Full stay', value: 'full_stay' },
      { text: 'Actions', value: 'actions', sortable: false, align: 'right' }
    ]
  })
};
</script>
